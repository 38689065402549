@import '@/styles/variables.scss';


























































































.wrapper-static {
  position: static;

  width: fit-content;
  .bubble-card {
    .v-image__image {
      background-color: white;
    }
  }
  .v-avatar {
    cursor: default;
    border: 3px solid;
  }
}
.iframe-container {
  height: 100%;
  iframe {
    min-height: calc(100vh - 340px);
  }
}
.v-overlay--support {
  .v-overlay__scrim {
    background-color: white !important;
  }
}
