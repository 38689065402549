@import '@/styles/variables.scss';
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.de {
  .st0 {
    fill: #6edec8;
  }
  .st1 {
    fill: #f9b60a;
  }
  .st2 {
    fill: #6bbdff;
  }
  .st3 {
    fill: #1895f9;
  }
  .st4 {
    enable-background: new;
  }
  .st5 {
    fill: #2930ce;
  }
  .st6 {
    fill: #b25b06;
  }
  .st7 {
    fill: #039181;
  }
  .st8 {
    fill: #f98504;
  }
  .st9 {
    fill: #37b59d;
  }
  .st10 {
    fill: #ffffff;
  }
  .st11 {
    fill: none;
  }
  .st12 {
    font-family: 'NHaasGroteskTXPro-65Md';
  }
  .st13 {
    font-size: 20px;
  }
  .st14 {
    font-family: 'NHaasGroteskTXPro-55Rg';
  }
  .st15 {
    font-family: 'NHaasGroteskTXPro-75Bd';
  }
  .st16 {
    font-size: 15px;
  }
}
.en {
  .st0 {
    fill: #6edec8;
  }
  .st1 {
    fill: #f9b60a;
  }
  .st2 {
    fill: #6bbdff;
  }
  .st3 {
    fill: #1895f9;
  }
  .st4 {
    fill: #2930ce;
  }
  .st5 {
    fill: #b25b06;
  }
  .st6 {
    fill: #039181;
  }
  .st7 {
    fill: #f98504;
  }
  .st8 {
    fill: #37b59d;
  }
  .st9 {
    fill: #ffffff;
  }
  .st10 {
    fill: none;
  }
  .st11 {
    font-family: 'NHaasGroteskTXPro-65Md';
  }
  .st12 {
    font-size: 19px;
  }
  .st13 {
    font-family: 'NHaasGroteskTXPro-75Bd';
  }
  .st14 {
    font-family: 'NHaasGroteskTXPro-55Rg';
  }
  .st15 {
    font-size: 15px;
  }
  .st16 {
    font-size: 20px;
  }
}
